exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catalogos-js": () => import("./../../../src/pages/catalogos.js" /* webpackChunkName: "component---src-pages-catalogos-js" */),
  "component---src-pages-contactos-js": () => import("./../../../src/pages/contactos.js" /* webpackChunkName: "component---src-pages-contactos-js" */),
  "component---src-pages-destaques-artevo-js": () => import("./../../../src/pages/destaques/artevo.js" /* webpackChunkName: "component---src-pages-destaques-artevo-js" */),
  "component---src-pages-destaques-slinovax-js": () => import("./../../../src/pages/destaques/slinovax.js" /* webpackChunkName: "component---src-pages-destaques-slinovax-js" */),
  "component---src-pages-destaques-vperfect-js": () => import("./../../../src/pages/destaques/vperfect.js" /* webpackChunkName: "component---src-pages-destaques-vperfect-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-orcamentos-js": () => import("./../../../src/pages/orcamentos.js" /* webpackChunkName: "component---src-pages-orcamentos-js" */),
  "component---src-pages-politica-cookies-js": () => import("./../../../src/pages/politica_cookies.js" /* webpackChunkName: "component---src-pages-politica-cookies-js" */),
  "component---src-pages-politica-privacidade-js": () => import("./../../../src/pages/politica_privacidade.js" /* webpackChunkName: "component---src-pages-politica-privacidade-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-produtos-formas-especiais-formas-index-js": () => import("./../../../src/pages/produtos/formas_especiais/formas/index.js" /* webpackChunkName: "component---src-pages-produtos-formas-especiais-formas-index-js" */),
  "component---src-pages-produtos-formas-especiais-index-js": () => import("./../../../src/pages/produtos/formas_especiais/index.js" /* webpackChunkName: "component---src-pages-produtos-formas-especiais-index-js" */),
  "component---src-pages-produtos-index-js": () => import("./../../../src/pages/produtos/index.js" /* webpackChunkName: "component---src-pages-produtos-index-js" */),
  "component---src-pages-produtos-janelas-abrir-basculantes-index-js": () => import("./../../../src/pages/produtos/janelas_abrir/basculantes/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-abrir-basculantes-index-js" */),
  "component---src-pages-produtos-janelas-abrir-harmonio-index-js": () => import("./../../../src/pages/produtos/janelas_abrir/harmonio/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-abrir-harmonio-index-js" */),
  "component---src-pages-produtos-janelas-abrir-index-js": () => import("./../../../src/pages/produtos/janelas_abrir/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-abrir-index-js" */),
  "component---src-pages-produtos-janelas-abrir-oscilo-batentes-index-js": () => import("./../../../src/pages/produtos/janelas_abrir/oscilo_batentes/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-abrir-oscilo-batentes-index-js" */),
  "component---src-pages-produtos-janelas-abrir-oscilo-paralelas-index-js": () => import("./../../../src/pages/produtos/janelas_abrir/oscilo_paralelas/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-abrir-oscilo-paralelas-index-js" */),
  "component---src-pages-produtos-janelas-correr-correr-index-js": () => import("./../../../src/pages/produtos/janelas_correr/correr/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-correr-correr-index-js" */),
  "component---src-pages-produtos-janelas-correr-elevatoria-index-js": () => import("./../../../src/pages/produtos/janelas_correr/elevatoria/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-correr-elevatoria-index-js" */),
  "component---src-pages-produtos-janelas-correr-index-js": () => import("./../../../src/pages/produtos/janelas_correr/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-correr-index-js" */),
  "component---src-pages-produtos-janelas-correr-synego-slide-index-js": () => import("./../../../src/pages/produtos/janelas_correr/synego_slide/index.js" /* webpackChunkName: "component---src-pages-produtos-janelas-correr-synego-slide-index-js" */),
  "component---src-pages-produtos-persianas-index-js": () => import("./../../../src/pages/produtos/persianas/index.js" /* webpackChunkName: "component---src-pages-produtos-persianas-index-js" */),
  "component---src-pages-produtos-persianas-monoblock-standard-index-js": () => import("./../../../src/pages/produtos/persianas/monoblock_standard/index.js" /* webpackChunkName: "component---src-pages-produtos-persianas-monoblock-standard-index-js" */),
  "component---src-pages-produtos-portada-exterior-index-js": () => import("./../../../src/pages/produtos/portada_exterior/index.js" /* webpackChunkName: "component---src-pages-produtos-portada-exterior-index-js" */),
  "component---src-pages-produtos-portada-exterior-portada-index-js": () => import("./../../../src/pages/produtos/portada_exterior/portada/index.js" /* webpackChunkName: "component---src-pages-produtos-portada-exterior-portada-index-js" */),
  "component---src-pages-produtos-portada-interior-dupla-index-js": () => import("./../../../src/pages/produtos/portada_interior/dupla/index.js" /* webpackChunkName: "component---src-pages-produtos-portada-interior-dupla-index-js" */),
  "component---src-pages-produtos-portada-interior-index-js": () => import("./../../../src/pages/produtos/portada_interior/index.js" /* webpackChunkName: "component---src-pages-produtos-portada-interior-index-js" */),
  "component---src-pages-produtos-portada-interior-simples-index-js": () => import("./../../../src/pages/produtos/portada_interior/simples/index.js" /* webpackChunkName: "component---src-pages-produtos-portada-interior-simples-index-js" */),
  "component---src-pages-produtos-portas-entrada-index-js": () => import("./../../../src/pages/produtos/portas/entrada/index.js" /* webpackChunkName: "component---src-pages-produtos-portas-entrada-index-js" */),
  "component---src-pages-produtos-portas-index-js": () => import("./../../../src/pages/produtos/portas/index.js" /* webpackChunkName: "component---src-pages-produtos-portas-index-js" */),
  "component---src-pages-produtos-portas-servico-com-fechadura-index-js": () => import("./../../../src/pages/produtos/portas/servico_com_fechadura/index.js" /* webpackChunkName: "component---src-pages-produtos-portas-servico-com-fechadura-index-js" */),
  "component---src-pages-sistemas-artevo-index-js": () => import("./../../../src/pages/sistemas/artevo/index.js" /* webpackChunkName: "component---src-pages-sistemas-artevo-index-js" */),
  "component---src-pages-sistemas-euro-design-index-js": () => import("./../../../src/pages/sistemas/euro_design/index.js" /* webpackChunkName: "component---src-pages-sistemas-euro-design-index-js" */),
  "component---src-pages-sistemas-index-js": () => import("./../../../src/pages/sistemas/index.js" /* webpackChunkName: "component---src-pages-sistemas-index-js" */),
  "component---src-pages-sistemas-slinova-index-js": () => import("./../../../src/pages/sistemas/slinova/index.js" /* webpackChunkName: "component---src-pages-sistemas-slinova-index-js" */),
  "component---src-pages-sistemas-slinovax-index-js": () => import("./../../../src/pages/sistemas/slinovax/index.js" /* webpackChunkName: "component---src-pages-sistemas-slinovax-index-js" */),
  "component---src-pages-sistemas-synego-index-js": () => import("./../../../src/pages/sistemas/synego/index.js" /* webpackChunkName: "component---src-pages-sistemas-synego-index-js" */),
  "component---src-pages-sistemas-synego-slide-index-js": () => import("./../../../src/pages/sistemas/synego_slide/index.js" /* webpackChunkName: "component---src-pages-sistemas-synego-slide-index-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

